export const CASES_STATUS ={
    PENDING : "Pending",
    PROCESSING : "Processing",
    COMPLETED: "Completed"
};

export const ASSIGN_TO ={
    DOCTOR : "Doctor",
};

export const PAGINATION ={
    LIMIT_6 : 6,
    LIMIT_10 : 10,
    LIMIT_20 : 20,
    LIMIT_50 : 50,
    LIMIT_100 : 100,
};

export const STP_RESULT = [
    {
        value: "PASS",
        text: "STP Pass",
        background: '#DBF4E9',
        color: '#11854D'
    },
    {
        value: "FAIL",
        text: "Failed",
        color: "#F04646",
        background: "#FFEEEE"
    },
    {
        value: "DIVERT_TO_MANUAL",
        text: "STP Manual Review",
        color: "#03B4CE",
        background: "#EDFBFC"
    },
    {
        value: "PROCESSING",
        text: "STP Processing",
        color: "#77777B",
        background: "#EBEBEB"
    },
];

export const STP_RULE = [
    {
        value: "PASS",
        text: "Pass",
        background: '#EFF8E5',
        color: '#7EC820'
    },
    {
        value: "FAIL",
        text: "Failed",
        color: "#F04646",
        background: "#FFEEEE"
    },
    {
        value: "DIVERT_TO_MANUAL",
        text: "Manual Review",
        color: "#00C1D5",
        background: "#CAF2F6"
    },
];

export const FILTER_CLAIM_STATUS = [
    {
        value: "PAC_INITIATED_PATIENT",
        text: "PAC initiated patient",
    },
    {
        value: "PAC_INITIATED_DOCTOR",
        text: "PAC initiated doctor",
    },
    {
        value: "PAC_SUMITTED",
        text: "PAC Submitted",
    },
    {
        value: "ASSESSMENT_IN_PROGRESS",
        text: "Assessment in Progress",
    },
    {
        value: "CLARIFICATION_REQUIRED",
        text: "Clarification Required",
    },
    {
        value: "PENDING_GE_APPROVAL",
        text: "Pending master approval",
    },
    {
        value: "PENDING_MASTER_ADVICE",
        text: "Pending master advice",
    },
    {
        value: "PENDING_SUPERVISOR_APPROVAL",
        text: "Pending supervisor approval",
    },
    {
        value: "REISSUED",
        text: "Cancelled (re-issued)",
    },
    {
        value: "APPROVED",
        text: "Approved",
    },
    {
        value: "REJECTED",
        text: "Rejected",
    },
    {
        value: "CANCELLED",
        text: "Cancelled",
    },
];

export const FILTER_CLAIM_STATUS_ARCHIVE = [
    {
        value: "APPROVED",
        text: "Approved",
    },
    {
        value: "REJECTED",
        text: "Rejected",
    },
    {
        value: "CANCELLED",
        text: "Cancelled",
    },
];

export const CLAIM_STATUS_VALUE_MAP_TEXT:{
    [key: string]: string;
} = {
    PAC_INITIATED_PATIENT: "PAC initiated patient",
    PAC_INITIATED_DOCTOR: "PAC initiated doctor",
    PAC_SUMITTED: "PAC Submitted",
    ASSESSMENT_IN_PROGRESS: "Assessment in Progress",
    CLARIFICATION_REQUIRED: "Clarification Required",
    PENDING_MASTER_APPROVAL: "Pending master approval",
    PENDING_SUPERVISOR_APPROVAL: "Pending supervisor approval",
    REISSUED: "Cancelled (re-issued)",
    PENDING_GE_APPROVAL: "Pending master approval",
    PENDING_MASTER_ADVICE: "Pending master advice",
    APPROVED: "Approved",
    REJECTED: "Rejected",
    CANCELLED: "Cancelled"
  };

export const COMMENT_MODE = {
    INTERNAL: "internal",
    DOCTOR: "doctor",
  };

export const MAP_COLOR_ROLE_COMMENT: {
    [key: string]: string;
} = {
    master: "#62AC03",
    supervisor: "#F0AD02",
    assessor: "#01A2FF",
    doctor: "#FE6B00",
    patient: "#6A57FC",
    supperadmin: "#F04646",
};

export const FILTER_CASE_STATUS = {
    ARCHIVE: "archive",
    AVAILABLE: "available",
}

export const CASE_TAG = {
    APPROVE_PANEL_RATE: "approve_panel_rate",
    NON_COMPLIANCE_FEES: "non_compliance_fees",
    REFER_NON_PANEL: "refer_non_panel",
    TOSP_CODING: "tosp_coding",
    CASE_ESC_DA: "case_esc_da",
    CASE_ESC_GE: "case_esc_ge",
};

export const ARR_PEDIATRIC_DOCTORS: Array<string> = [
    'Ooi Pei Ling',
    'Ng Yong Meng Winston Irving',
    'Heng Joo Teck',
    'Snodgrass Alison Marion',
    'Cheong Yee Ling',
    'Aung LeLe',
    'Wendy Sinnathamby',
    'Christina Ong',
    'Yeo Tong Hong',
    'Tan Zhen Han',
    'Lee Alison Joanne',
    'CHAN KIT YEE',
    'Wong Chin Khoon',
    'Tan Nancy',
    'TANG POH LIN JENNY',
    'Ratna Sridjaja',
    'Andrea Yeo',
    'WONG PIK YOU ADELINE',
    'Tan Hung Tiong',
    'Darryl Lim Ching Wen',
    'Lim Kwang Hsien',
    'Tan Hwa Min Terence',
    'Gong Wei Kin',
    'Lee Jun Theodric',
    'Raut Pradeep Prakash',
    'Ng Pau Ling Simon'
]

export const MAX_SIZE_UPLOAD = 4.5;

export const INJURY_QUESTIONAIRE_RELEASE_DATE = "2024-10-03T00:00:00.000Z";
export const UPDATE_ATTENDANCE_FEE_RELEASE_DATE = "2025-01-08T00:00:00.000Z";
export const DIAGNOSIS_RELATED_TO_INJURY_QUESTIONNAIRE_RELEASE_DATE = '2024-10-03T00:00:00.000Z';
export const DIAGNOSIS_RELATED_TO_INJURY_MORE_QUESTIONS_RELEASE_DATE = '2024-11-29T00:00:00.000Z';
export const UPDATE_ANAESTHETIST_FEE_RELEASE_DATE = "2025-01-01";
export const UPDATE_DEFAULT_GW_FEE_PEDIATRIC_RELEASE_DATE = "2025-02-05";